import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import {
  ParentUpload,
  PdfViewer,
} from "../../../../../Components/UploadBox/UploadBox";
import { useNavigate } from "react-router-dom";
import {
  assignmentDownload,
  assignmentView,
  getProfileData,
  getStudentAssignment,
} from "../../../../../Service/BaseService/BaseService";
import "./index.css";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../../Components/Snackbar/Snackbar";
function AssignmentStudent() {
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("User");
  const accessToken = JSON.parse(userDetails);
  const studentDetails = localStorage.getItem("studentDetails");
  const studentid = localStorage.getItem("studentid");
  const studentId = JSON.parse(studentid);
  const [isLoading, setIsLoading] = useState(false);
  const [rowDataDefault, setRowDataDefault] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isStudentData, setIsStudentData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(studentId);
  console.log();
  const [isViewAssignemnt, setIsViewAssignment] = useState(false);
  const addAssignment = () => { };
  const uploadHandler = (params) => {
    navigate(`/assignment-details`, { state: { params } });
    // setIsModal(true);
  };

  useEffect(() => {
    fetchAllData(studentId);
    getProfile();
  }, []);

  const assignmentFile = async (id, name) => {
    try {
      const response = await assignmentDownload(accessToken.token, id);
      if (!response.ok) {
        throw new Error("Failed to export data");
      }
      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let fileName = "file";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifySucess("Export successful");
    } catch (err) {
      notifyError("Error on export");
    }
  };

  const getProfile = async () => {
    try {
      const response = await getProfileData(accessToken.token);
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        setStudentData(responseData.message.Student);
        setIsStudentData(responseData.message);
      }
    } catch (err) {
      console.error("Error occurred while fetching profile:", err);
    }
  };
  const fetchAllData = async (id) => {
    console.log(id);
    setIsLoading(true);
    try {
      const response = await getStudentAssignment(accessToken.token, id);
      const responseData = await response.json();
      if (response.ok && responseData.message.length > 0) {
        const headers = Object.keys(responseData.message[0]);

        const additionalColumnUpdate = {
          field: "Download",
          headerName: "Assigned",
          width: 180,
          renderCell: (params) => {
            if (
              !params.row?.assignmentId ||
              params.row.assignmentId.length === 0
            ) {
              return null;
            }
            return (
              <div
                className="Btn"
                onClick={() =>
                  assignmentFile(
                    params.row?._id,
                    params.row?.assignmentId?.name
                  )
                }
              >
                <svg
                  className="svgIcon"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                </svg>
                <span className="icon2"></span>
              </div>
            );
          },
        };
        const additionalColumnsubmit = {
          field: "submit",
          headerName: "Submitted",
          width: 180,
          renderCell: (params) => (
            <div>
              {params.row?.uploadedFiles.map((file, index) => (
                <p
                  key={index}
                  className="assignment-name"
                  style={{ cursor: "pointer" }}
                  onClick={() => assignmentViewer(file._id)}
                >
                  {file.filename}
                </p>
              ))}
            </div>
          ),
        };

        const additionalColumnEdit = {
          field: "Edit",
          headerName: "Upload",
          width: 100,
          renderCell: (params) => {
            const isDisabled = params.row.status === "in-review";
        
            return (
              <div
                className={`cssbuttons-io-button ${isDisabled ? "disabled" : ""}`}
                onClick={() => {
                  if (isDisabled) {
                    notifyError("Assignment in review. Can't upload.");
                  } else {
                    uploadHandler(params.row);
                  }
                }}
                style={{
                  pointerEvents: isDisabled ? "auto" : "auto", // Still allows click to trigger notifyError
                  opacity: isDisabled ? 0.5 : 1,
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
              >
                <svg
                  className="upload-svg"
                  viewBox="0 0 640 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                </svg>
              </div>
            );
          },
        };
        
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        };
        const headersWithoutScheduleDate = headers.filter(
          (header) => header !== "ScheduleDate"
        );

        const columns = [
          ...headersWithoutScheduleDate.map((header) => {
            if (header === "assignmentId") {
              return {
                field: "assignmentId",
                headerName: "Name",
                flex: 1,
                width: 100,
                renderCell: (params) => {
                  return (
                    <div>
                      <p className="assignment-name">
                        {params.row?.assignmentId?.name}
                      </p>
                    </div>
                  );
                },
              };
            } else if (header === "dueDate") {
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                width: 100,
                renderCell: (params) => {
                  return (
                    <div>
                      <p className="tabel-cell">
                        {formatDate(params.row?.dueDate)}
                      </p>
                    </div>
                  );
                },
              };
            } else if (header === "status") {
              return {
                field: "status",
                headerName: "Status",
                flex: 1,
                width: 100,
                renderCell: (params) => {
                  return (
                    <div>
                      <p className="table-cell mt-3 textcolor">
                        {params.row.status.charAt(0).toUpperCase() +
                          params.row.status.slice(1)}
                      </p>
                    </div>
                  );
                },
              };
            } else {
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 100,
              };
            }
          }),
          additionalColumnsubmit,
          additionalColumnUpdate,
          additionalColumnEdit,
        ];

        const rows = responseData.message.map((dataItem, index) => ({
          id: index,
          ...dataItem,
        }));

        setColumnDefs(
          columns.filter(
            (item, index) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "classId" &&
              item.field !== "createdAt" &&
              item.field !== "updatedBy" &&
              item.field !== "studentId" &&
              item.field !== "updatedAt" &&
              item.field !== "uploadedFiles" &&
              item.field !== "originalFile" &&
              item.field !== "scheduleDate"
          )
        );

        // setRowDataDefault(rows);
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
    setIsLoading(false);
  };
  const handleSelectChange = async (event) => {
    const newSelectedStudent = event.target.value;
    setSelectedStudent(newSelectedStudent);
    console.log(newSelectedStudent);

    await fetchAllData(newSelectedStudent);
  };
  const assignmentViewer = async (fileId) => {
    console.log(fileId);
    try {
      const response = await assignmentView(accessToken.token, fileId);
      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const responseData = await response.json();

      const pdfContent = responseData.message;

      setIsViewAssignment(pdfContent);
      setIsModal(true);
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
    }
  };
  const modalHandler = () => {
    setIsModal(!isModal);
  };

  return (
    <Container>
      <Snackbar />
      <PdfViewer
        modal={isModal}
        modalHandler={modalHandler}
        isViewAssignment={isViewAssignemnt}
      />
      {/* <ParentUpload modal={isModal} modalHandler={modalHandler} /> */}

      <Row className="justify-content-end">
        <Col md={2}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Select
              className="modal-input"
              name="classRoomId"
              style={{ cursor: "pointer" }}
              value={selectedStudent}
              onChange={handleSelectChange}
            >
              {studentData.map((student) => (
                <option key={student._id} value={student._id}>
                  {student.firstName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="product mt-3">
            <SearchBar
              onClick={addAssignment}
              value={"Assignment"}
              hide={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <AdvanceTable
            rows={rowData}
            columns={columnDefs}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AssignmentStudent;
